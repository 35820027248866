import { Box, Card, CardContent, CircularProgress, Grid, Typography } from "@mui/material"
import { format } from "date-fns";
import { auth, useAuthStateWithClaims } from "../firebase";

import card from "../assets/card.png";
import QRCode from "react-qr-code";

const MemberCard = () => {
    const { user, claims, loading, error } = useAuthStateWithClaims(auth);

    if (loading || !claims) {
        return <CircularProgress />;
    }

    if (error || !user) {
        return <Typography variant="h4">An error occured. Please try again later.</Typography>
    }

    const memberSince = new Date(claims.membership.memberSince!)

    return <Box maxWidth="sm" sx={{ my: 5, marginLeft: "auto", marginRight: "auto" }}>
        <Card sx={{ aspectRatio: "85.6/54.0", borderRadius: "20px", backgroundImage: `url(${card})`, backgroundSize: "contain" }} raised>
            <Box sx={{ width: "100%", backgroundColor: "#e6e6e6", textAlign: "center", marginTop: "20px", padding: { sm: "5px 0", xs: "2px 0" } }}>
                <Typography sx={{ textTransform: "uppercase", fontFamily: "Oswald", fontSize: { sm: "1.3rem", xs: "1.0rem" } }}>{claims.membership.type || "Lifetime"} Member</Typography>
            </Box>
            <CardContent sx={{ textAlign: "left", padding: { sm: "20px", xs: "20px" } }}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography color="white" sx={{ typography: { sm: "h6", xs: "body1" } }}>{user.displayName}</Typography>
                        <Typography color="white" sx={{ typography: { sm: "body1", xs: "body2" } }}>{claims.uniData.college!.name} College</Typography>
                        {claims.membership.memberSince && <Typography color="white" sx={{ typography: { sm: "body1", xs: "body2" } }}>Member since {format(memberSince, "MMMM y")}</Typography>}
                    </Grid>
                    <Grid item xs={4} sx={{ justifyContent: "flex-end" }}>
                        <Box sx={{ padding: { sm: "8px", xs: "4px" }, backgroundColor: "white" }}>
                            <QRCode style={{ height: "auto", maxHeight: "100%", width: "100%" }} value={user.email!} />
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Box>
}

export default MemberCard