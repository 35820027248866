import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';

import './App.css';
// import Footer from './components/Footer';
import NavBar from './components/NavBar';

function App() {
  return (
    <div className="App">
      <NavBar />
      <main>
        <Container maxWidth="md" sx={{ pt: 4 }}>
          <Outlet />
        </Container>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
