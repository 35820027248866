import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { PortableText } from "@portabletext/react";
import React from "react";
import { Await, useLoaderData } from "react-router-dom";
import { Ambassador } from "../sanity";

const AmbassadorProfile = () => {
    const data = useLoaderData() as { ambassador: Ambassador }

    return <>
        <Typography variant="h4" gutterBottom>Ambassador Profile</Typography>
        <React.Suspense fallback={<CircularProgress />}>
            <Await resolve={data.ambassador} errorElement={<Typography variant="body1">Error loading ambassador profile.</Typography>}>
                {(ambassador: Ambassador) => {
                    return <>
                        <Typography paragraph color="text.secondary" variant="h6" gutterBottom>{ambassador.name} - {ambassador.location}</Typography>
                        <Box sx={{ textAlign: "left", my: 5 }}>
                            <PortableText value={ambassador.content} />
                        </Box>
                        <Box sx={{ mb: 5 }}>
                            <Button
                                variant="contained"
                                target="_blank"
                                href={`mailto:ambassadors@travelsociety.cam?subject=Ambassador Contact: ${ambassador.name} - ${ambassador.location}`}
                            >
                                Contact {ambassador.name}
                            </Button>
                        </Box>
                    </>
                }}
            </Await>
        </React.Suspense>
    </>
};

export default AmbassadorProfile;