import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface TypographyVariants {
      logo1: React.CSSProperties;
      logo2: React.CSSProperties;
    }
  
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        logo1?: React.CSSProperties;
        logo2?: React.CSSProperties;
    }
  }
  
  // Update the Typography's variant prop options
  declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        logo1: true;
        logo2: true;
    }
  }

const theme = createTheme({
    typography: {
      logo1: {
        fontSize: '1.5rem',
        fontFamily: 'Oswald'
      },
      logo2: {
        fontSize: '1.2rem',
        fontFamily: 'Oswald'
      },
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#141b27',
      },
      secondary: {
        main: '#e0b342',
      },
      background: {
        paper: '#e6e6e6',
      },
      info: {
        main: '#035285',
      },
    },
  });

  export default theme