import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { auth } from "../firebase";
import { CircularProgress } from "@mui/material";

const RequireAuth = () => {
    const [user, loading] = useAuthState(auth);
    const location = useLocation();

    if (loading) {
        return <CircularProgress />
    } else {
        return user ? (
            <Outlet />
        ) : (
            <Navigate to="/login" replace state={{ path: location.pathname }} />
        );
    }
}

export default RequireAuth