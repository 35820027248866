import { Button, CircularProgress, Icon } from '@mui/material';
import { GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase';

const LogIn = () => {
    const [user, loading] = useAuthState(auth);
    const { state } = useLocation()


    const logInRedirect = () => {
        signInWithRedirect(auth, new GoogleAuthProvider().setCustomParameters({ hd: 'cam.ac.uk' }))
    }

    if (loading) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    if (user) {
        return <Navigate to={state?.path || '/'} replace={true} />
    }

    return (
        <div>
            <Button variant="contained" size="large" startIcon={<Icon>login</Icon>} onClick={logInRedirect}>Log in via Raven</Button>
        </div>
    )

}

export default LogIn