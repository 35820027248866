import { CircularProgress, Typography, Box, TextField, Stack, Icon, Snackbar, Alert } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { auth, functions, useAuthStateWithClaims } from "../firebase";
import { capitalise } from "../utils";
import { useState } from "react";
import { httpsCallable } from "@firebase/functions";
import { useNavigate } from "react-router-dom";

const Onboard = () => {
    const { user, claims, loading, error } = useAuthStateWithClaims(auth);
    const [onboardingSubmitted, setOnboardingSubmitted] = useState(false);
    const [showOnboardingErrorSnackbar, setShowOnboardingErrorSnackbar] = useState(false);
    const navigate = useNavigate();

    if (loading || !claims) {
        return <CircularProgress />;
    }

    if (error || !user) {
        return <Typography variant="h4">An error occured. Please try again later.</Typography>
    }

    const handleOnboard = async () => {
        setOnboardingSubmitted(true);
        const onboardMember = httpsCallable(functions, "onboardMember");
        try {
            await onboardMember();
            // Force refresh claims
            await auth.currentUser?.getIdToken(true);
            navigate("/");
        } catch (e) {
            setOnboardingSubmitted(false);
            setShowOnboardingErrorSnackbar(true);
            console.error(e);
        }
    }

    return (
        <>
            <Typography variant="h4" gutterBottom>Become a Member</Typography>
            <Typography paragraph color="text.secondary" variant="body1" gutterBottom>Thank you for choosing to be a Travel Society member. Please confirm your details below to complete sign up.</Typography>
            <Box sx={{ mt: 4 }}>
                <Stack>
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="Name"
                        defaultValue={user.displayName}
                    />
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="Email Address"
                        defaultValue={user.email}
                    />
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="College"
                        defaultValue={claims.uniData.college!.name}
                    />
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="Student Type"
                        defaultValue={claims.uniData.studentType ? capitalise(claims.uniData.studentType) : "Unknown"}
                    />
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="Membership Type"
                        defaultValue="Lifetime (Free!)"
                    />
                    <LoadingButton sx={{ my: 2 }} loading={onboardingSubmitted} loadingPosition="start" startIcon={<Icon>check</Icon>} variant="contained" onClick={handleOnboard}><span>Confirm</span></LoadingButton>
                </Stack>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={showOnboardingErrorSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowOnboardingErrorSnackbar(false)}
            >
                <Alert onClose={() => setShowOnboardingErrorSnackbar(false)} severity="error">An error occured. Please try again later.</Alert>
            </Snackbar>
        </>
    )
}

export default Onboard