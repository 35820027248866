import { Box, Card, CardActionArea, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { Await, useLoaderData, useNavigate } from "react-router-dom";
import { Ambassador } from "../sanity";

const Ambassadors = () => {
    const data = useLoaderData() as { ambassadors: Ambassador[] };
    const navigate = useNavigate();

    return <>
        <Typography variant="h4" gutterBottom>Ambassadors</Typography>
        <Typography paragraph color="text.secondary" variant="body1" gutterBottom>
            Travel is so much more enjoyable and stress-free when you have first-hand advice and tips from a local or someone acquainted with where you are. A familiar face in an unfamiliar place makes all the difference. This is the aim of the Travel Society Ambassadors programme.
        </Typography>
        <Typography paragraph color="text.secondary" variant="body1" gutterBottom>
            Our Ambassadors represent their hometown, region or country or somewhere they've visited. Browse their profiles for useful travel tips and contact any of them for more advice!
        </Typography>
        <Box sx={{ my: 5 }}>
            <React.Suspense fallback={<CircularProgress />}>
                <Await resolve={data.ambassadors} errorElement={<Typography variant="body1">Error loading ambassador profiles.</Typography>}>
                    {(ambassadors: Ambassador[]) => {
                        return <Grid container spacing={2}>
                            {ambassadors.map(ambassador => {
                                return <Grid item xs={12} sm={6} key={ambassador._id}>
                                    <Card>
                                        <CardActionArea onClick={() => navigate(`/ambassador/${ambassador._id}`)}>
                                            <CardContent>
                                                <Typography variant="h5">{ambassador.name}</Typography>
                                                <Typography variant="subtitle1">{ambassador.location}</Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            })}
                        </Grid>
                    }}
                </Await>
            </React.Suspense>
        </Box>
    </>
}

export default Ambassadors;