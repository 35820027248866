import { createBrowserRouter, defer } from "react-router-dom"

import App from "../App";
import Home from "./Home";
import LogIn from "./LogIn";
import Profile from "./Profile";
import RequireAuth from "../guards/RequireAuth";
import Onboard from "./Onboard";
import Ambassadors from "./Ambassadors";
import { getAmbassadorById, getAmbassadors } from "../sanity";
import Ambassador from "./Ambassador";
import RequireMember from "../guards/RequireMember";
import Blog from "./Blog";
import Events from "./Events";

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "login",
        element: <LogIn />,
      },
      {
        element: <RequireAuth />,
        children: [
          {
            path: "",
            element: <Home />,
          },
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: "onboard",
            element: <Onboard />,
          },
          {
            element: <RequireMember />,
            children: [
              {
                path: "blog",
                element: <Blog />
              },
              {
                path: "events",
                element: <Events />
              },
              {
                path: "ambassadors",
                element: <Ambassadors />,
                loader: async () => {
                  return defer({ ambassadors: getAmbassadors() });
                }
              },
              {
                path: "ambassador/:id",
                element: <Ambassador />,
                loader: async ({ params }) => {
                  return defer({ ambassador: getAmbassadorById(params.id!) });
                }
              }
            ]
          }
        ]
      }
    ]
  },
]);

export default router