import { Box, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import MemberCard from "../components/MemberCard";
import { auth, useAuthStateWithClaims } from "../firebase";
import { capitalise } from "../utils";

const Profile = () => {
    const { user, claims, loading, error } = useAuthStateWithClaims(auth);

    if (loading || !claims) {
        return <CircularProgress />;
    }

    if (error || !user) {
        return <Typography variant="h4">An error occured. Please try again later.</Typography>
    }

    return (
        <Box>
            <Typography variant="h4" gutterBottom>Profile</Typography>
            {claims.membership.member && <MemberCard />}
            <Box sx={{ my: 4 }}>
                <Stack>
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="Name"
                        defaultValue={user.displayName}
                    />
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="Email Address"
                        defaultValue={user.email}
                    />
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="College"
                        defaultValue={claims.uniData.college!.name}
                    />
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="Student Type"
                        defaultValue={claims.uniData.studentType ? capitalise(claims.uniData.studentType) : "Unknown"}
                    />
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="Membership Type"
                        defaultValue={claims.membership.member ? capitalise(claims.membership.type || "Lifetime") : "None"}
                    />
                    <TextField
                        sx={{ my: 1 }}
                        variant="filled"
                        disabled
                        label="Member Since"
                        defaultValue={claims.membership.member ? new Date(claims.membership.memberSince!).toLocaleDateString() : "N/A"}
                    />
                </Stack>
            </Box>
        </Box>
    );
}

export default Profile