import {createClient} from "@sanity/client";

const client = createClient({
  projectId: "napcuyvr",
  dataset: "production",
  useCdn: true
});

export default client

export type Ambassador = {
    _createdAt: Date;
    _id:        string;
    _rev:       string;
    _type:      string;
    _updatedAt: Date;
    name:       string;
    location:   string;
    content:    any;
}

export const getAmbassadors = async (): Promise<Ambassador[]> => {
    const query = `*[_type == "ambassador"]`
    const ambassadors = await client.fetch(query)
    return ambassadors
}

export const getAmbassadorById = async (id: string): Promise<Ambassador> => {
    const query = `*[_type == "ambassador" && _id == "${id}"]`
    const [ambassador] = await client.fetch(query)
    return ambassador
}