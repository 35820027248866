import { Navigate, Outlet } from "react-router-dom";
import { auth, useAuthStateWithClaims } from "../firebase";
import { CircularProgress, Typography } from "@mui/material";

const RequireMember = () => {
    const { user, claims, loading, error } = useAuthStateWithClaims(auth);

    if (loading || !claims) {
        return <CircularProgress />
    } else if (error || !user) {
        return <Typography variant="body1">An error occured. Please try again later.</Typography>
    } else {
        return claims.membership.member ? (
            <Outlet />
        ) : (
            <Navigate to="/" replace />
        );
    }
}

export default RequireMember