import { Box, Button, CircularProgress, Container, Icon, Typography } from "@mui/material"
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { auth, useAuthStateWithClaims } from "../firebase"

const Home = () => {
    const navigate = useNavigate();
    const { user, claims, loading, error } = useAuthStateWithClaims(auth);

    if (loading || !claims) {
        return <CircularProgress />;
    }

    if (error || !user) {
        return <Typography variant="h4">An error occured. Please try again later.</Typography>
    }

    const handleViewMembershipCard = () => {
        navigate('/profile')
    }

    const handleBecomeMember = () => {
        navigate('/onboard')
    }

    const membershipStatus = () => {
        if (claims.membership.member) {
            const memberSince = new Date(claims.membership.memberSince!)
            return <><Typography paragraph color="text.secondary" variant="h6" gutterBottom>You have been a Travel Society member since {format(memberSince, 'MMMM y')}.</Typography>
                <Button sx={{ my: 2 }} startIcon={<Icon>card_membership</Icon>} variant="contained" onClick={handleViewMembershipCard}>View membership card</Button>
            </>
        } else {
            return <><Typography paragraph color="text.secondary" variant="h6" gutterBottom>You are currently not a Travel Society member.</Typography>
                <Typography paragraph color="text.secondary" variant="subtitle1" gutterBottom>Becoming a member is free and gives you access to our newsletter, events, trips, discounts and more.</Typography>
                <Button sx={{ my: 2 }} startIcon={<Icon>person_add</Icon>} variant="contained" onClick={handleBecomeMember}>Become a member</Button></>
        }
    }

    return (
        <>
            <Box>
                <Container>
                    <Typography variant="h2" gutterBottom>Hi {user!.displayName!.split(" ")[0]}!</Typography>
                    {membershipStatus()}
                </Container>
            </Box>
        </>
    )
}

export default Home