import { initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAOV7RddftE_I2-wqF1eO_zXsKZQSXjBTU",
    authDomain: "members.travelsociety.cam",
    projectId: "cu-travel-society",
    storageBucket: "cu-travel-society.appspot.com",
    messagingSenderId: "333840915966",
    appId: "1:333840915966:web:df1ead2fd21409472734af",
    measurementId: "G-L66Y41KRJV"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const functions = getFunctions(app, "europe-west2");

export {auth, functions};

type College = {
    id: string
    name: string
}

enum StudentType {
    Undergraduate = "undergraduate",
    Postgraduate = "postgraduate",
}

type UniData = {
    college?: College
    matriculationYear?: number
    studentType?: StudentType
}

type Membership = {
    member: boolean
    type?: "lifetime" | "annual"
    memberSince?: Date
}

type CustomClaims = {
    uniData: UniData
    membership: Membership
}

export const useAuthStateWithClaims = (auth: Auth) => {
    const [claims, setClaims] = useState<CustomClaims | null>(null);
    const [user, loading, error] = useAuthState(auth, {
        onUserChanged: async (user) => {
            if (user) {
                const idTokenResult = await user.getIdTokenResult();
                setClaims(idTokenResult.claims as CustomClaims);
            } else {
                setClaims(null);
            }
        }
    })

    return {user, claims, loading, error};
}